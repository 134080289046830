<template>
  <div class="body-breadcrumb d-flex align-items-center">
    <b-container class="custom-container px-0 px-md-3">
      <ul>
        <li v-for="(item, i) in $store.state.breadCrumbItems" :key="i">
          <router-link v-if="item.href" :to="item.href">
            {{ item.text }}
          </router-link>
          {{ item.href ? "" : item.text }}
        </li>
      </ul>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Breadcrumb",
  methods: {},
};
</script>
