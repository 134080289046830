<template>
  <lottie-vue-player
    class="lottie-file"
    src="/lottie-files/74499-circle.json"
    :autoplay="true"
    :showColorPicker="true"
    :loop="true"
  ></lottie-vue-player>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
