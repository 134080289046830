<template>
  <div class="w-100">
    <b-container class="mt-5 pt-3">
      <b-row>
        <b-col cols="12" class="text-center mb-4">
          <h3 class="title-border-buttom">
            <lottie-circle></lottie-circle>
            درباه سیمان آینده
          </h3>
        </b-col>

        <b-col cols="12">
          <p>
            شرکت سیمان آینده یکی از بزرگترین شرکت ها در حوزه فروش اینترنتی مصالح
            ساختمانی می باشد.
          </p>
          <p>
            ما می خواهیم تمام قسمت های ساخت ساز را از خرید مصالح تا خرید املاک
            را به صورت یک محصول دیجیتال در رسترس تبدیل کنیم.در حال حاظر شما
            میتوانید مصالح ساختمانی را از جمله سیمان را به سه صورت خرید از ما،
            خرید از بورس کالا و خرید از کاربران سیمان آینده انجام دهید.
          </p>
          <br /><br /><br /><br />
        </b-col>
        <b-col cols="12" class="text-center mb-4">
          <h3 class="title-border-buttom">
            <lottie-circle></lottie-circle>
            چگونه میتوانیم از سیمان آینده خرید کنیم؟
          </h3>
        </b-col>
        <b-col cols="12" class="col-md-6 pl-0 pl-md-5">
          <h4 class="bold fs-14 position-relative mt-4">
            <div class="square_number_title">
              <span class="material-icons el-center">store</span>
              <lottie-circle></lottie-circle>
            </div>
            خرید از ما
          </h4>
          <p>
            شرکت آینده طرف قرارداد با شرکت های شاخص حوزه مصالح ساختمانی است و
            شما می توانید سفارش های خود را از میان محصولات ما انجام دهید.
          </p>

          <h4 class="bold fs-14 position-relative mt-4">
            <div class="square_number_title">
              <span class="material-icons el-center">outbound</span>
              <lottie-circle></lottie-circle>
            </div>
            خرید از بورس کالا
          </h4>
          <p>
            شما می توانید از طریق پلتفرم آنلاین سیمان آینده مصالح ساختمانی مورد
            نظر خود را از بورس خریداری فرمائید.
          </p>

          <h4 class="bold fs-14 position-relative mt-4">
            <div class="square_number_title">
              <span class="material-icons el-center">people</span>
              <lottie-circle></lottie-circle>
            </div>
            خرید از کاربران
          </h4>
          <p>
            کاربرانی که از شرکت آینده خرید کرده و محصولات خود را در انبار های
            مرتبط با شرکت آینده نگهداری می کنند، می توانند محصولات خریداری شده
            را در پلتفرم آینده را با قیمت های دلخواه به کاربران دیگر بفروشند.
          </p>
        </b-col>
        <b-col cols="12" class="col-md-6 ps-3 ps-md-0 mb-5">
          <img
            src="/images/online-pay.jpg"
            class="rounded"
            alt=""
            width="100%"
          />
        </b-col>

        <b-col cols="12" class="text-center my-4">
          <h3 class="title-border-buttom">
            <lottie-circle></lottie-circle>
            محصولات چه شرکت هایی در سیمان آینده در دسترس است؟
          </h3>
        </b-col>
        <b-col cols="12" class="mb-5">
          <div class="content-factory">
            <b-col
              v-for="logo in logos"
              :key="logo.id"
              cols="4"
              sm="3"
              md="2"
              class="factory-item"
            >
              <div
                class="logo-factory"
                :style="{ backgroundImage: 'url(' + logo.image + ')' }"
              ></div>
            </b-col>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LottieCircle from "./../components/LottieCircle";

export default {
  data() {
    return {
      logos: [
        { id: 1, image: "/images/factory/arad-logo-400.png" },
        { id: 1, image: "/images/factory/bagheran_old.jpg" },
        { id: 1, image: "/images/factory/darab.png" },
        { id: 1, image: "/images/factory/estehan.jpg" },
        { id: 1, image: "/images/factory/hegmatan.png" },
        { id: 1, image: "/images/factory/mashhad-factory.jpg" },
        { id: 1, image: "/images/factory/sefid-neyriz.png" },
        { id: 1, image: "/images/factory/simanmahan.png" },
        { id: 1, image: "/images/factory/kerman.jpg" },
      ],
    };
  },
  components: { LottieCircle },
  methods: {
    getUrl(url) {
      return "url(" + url + ")";
    },
  },
  mounted() {
    console.log(this.logos);
  },
};
</script>

<style scoped>
.content-factory {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 13px;
}
.factory-item {
  transform: scale(1);
  -webkit-transform: scale(1);
  /*position: relative;*/
  padding: 50%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 3px 6px #00000029;
}
.logo-factory {
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media (max-width: 768px) {
  .content-factory {
    grid-template-columns: auto auto auto auto;
  }
}
@media (max-width: 992px) {
  .content-factory {
    grid-template-columns: auto auto auto auto auto auto;
  }
}
@media (min-width: 992px) {
  .content-factory {
    grid-template-columns: auto auto auto auto auto auto auto;
  }
}
</style>
