<template>
  <div class="home_slider">
    <div>
      <div class="container">
          <div class="img-bg">
            <img src="../assets/images/circles.png" width="auto" height="100%">
          </div>
          <div class="row m-0">
            <div class="col-12 col-md-8 box-text-slider">
              <h1 class="title-bold-txt">نخستین سامانه آنلاین فروش سیمان</h1>
              <h3 class="title-small">خرید و فروش، پیگیری لحظه ای سفارش ها، و مدیریت مالی</h3>
              <h3 class="title-small">سریع و دقیق همگام با نیاز های بازار سیمان</h3>
              <div class="mt-4">
                <button class="btn-a">
                  <router-link to="/store" target="_blank">
                    مشاهده فروشگاه 
                  </router-link>
                </button>
                <button class="btn-a mr-4">
                  <a href="https://panel.ayandehcement.com" target="_blank">
                  ورود به سیستم
                  <span class="material-icons-outlined align-middle">arrow_circle_left</span>
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="triangle-top">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox="0 0 2 1"
      >
        <g><path class="triangle-path" d="M0,0 l2,0 l-1,1 z" /></g>
      </svg>
    </div>
    <b-modal
      v-model="modalShow"
      centered
      size="lg"
      title="ورود به پنل"
      hide-footer
    >
      <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-between my-3"
      >
        <div class="panel-box">
          <h5 data-v-78fccaf4="" class="text-center mb-3">پنل کاربری</h5>
          <div
            class="image-box position-relative"
            style="background-image: url('/images/new-panel.png')"
            @mouseover="showUserPanel = true"
            @mouseleave="showUserPanel = false"
          >
            <div
              v-if="showUserPanel"
              class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded"
              style="background-color: rgba(0, 0, 0, 0.2)"
            >
              <b-button
                variant="primary"
                href="https://panel.ayandehcement.com/"
                >ورود به پنل کاربری</b-button
              >
            </div>
          </div>
        </div>
        <div class="panel-box mt-5 mt-md-0">
          <h5 class="text-center mb-3">پنل باربری</h5>
          <div
            class="image-box position-relative"
            style="background-image: url('/images/old-panel.png')"
            @mouseover="showBarPanel = true"
            @mouseleave="showBarPanel = false"
          >
            <div
              v-if="showBarPanel"
              class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded"
              style="background-color: rgba(0, 0, 0, 0.2)"
            >
              <b-button
                variant="primary"
                href="https://admin.ayandehcement.com/"
                >ورود به پنل باربری</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/css/swiper.css";

export default {
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      modalShow: false,
      showUserPanel: false,
      showBarPanel: false,
    };
  },
  components: {
  },
};
</script>

<style scoped lang="scss">
.panel-box {
  width: 45%;
  height: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .image-box {
    width: 100%;
    min-height: 200px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 5px;
    border: 2px solid #0a3450;
    @media screen and (max-width: 768px) {
      min-height: 150px;
    }
  }
}
</style>
