<template>
  <div class="mobile-menu">
    <div class="mobile-menu-items d-flex justify-content-between">
      <div class="items">
        <router-link to="/" :class="this.$route.name == 'Home' && 'active'">
          <div class="mb-2">
            <span class="material-icons-outlined align-middle">home</span>
          </div>
          <div>
          صفحه اصلی
          </div>
        </router-link>
      </div>
      <div class="items ml-3" :class="this.$route.name == 'Faq' && 'active'">
        <router-link to="/faq" class="items ml-3" :class="this.$route.name == 'Faq' && 'active'">
          <div class="mb-2">
            <span class="material-icons-outlined align-middle">help</span>
          </div>
          <div>
           سوالات متداول
          </div>
        </router-link>
      </div>
      <div class="text-center mr-1">
        <router-link to="/store" class="items ml-3" :class="this.$route.name == 'Faq' && 'active'">
          <div class="item-store">
            <span class="material-icons-outlined align-middle">store</span>
          </div>
          <div class="mt-4 fs-12">
            فروشگاه
          </div>
        </router-link>
      </div>
      <div class="items mr-4">
        <router-link to="/contact_us" class="items ml-3" :class="this.$route.name == 'ContactUs' && 'active'">
          <div class="mb-2">
            <span class="material-icons-outlined align-middle">call</span>
          </div>
          <div>
           تماس با ما
          </div>
        </router-link>
      </div>
      <div class="items">
        <a href="https://panel.ayandehcement.com" target="_blank">
          <div class="mb-2">
            <span class="material-icons-outlined align-middle">person</span>
          </div>
            ورود
        </a>
      </div>
    </div>
    <div class="mobile-menu-bg">
      <img
        src="~@/assets/images/bg-mobile-menu.svg"
        class="w-full h-full object-cover drop-shadow-[0_0_12px_rgba(0,0,0,0.12)]"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: "MobileMenu",
  computed: {
    ShopActive: function () {
      if (this.$route.matched[1].path == "/shop") return true;
      return false;
    },
    HomeActive: function () {
      if (this.$route.matched[1].path == "/") return true;
      return false;
    },
  },
  mounted() {
    // console.log(this.HomeActive);
  },
};
</script>

<style scoped>
.mobile-menu-bg {
  position: absolute;
  top: 1px;
  right: 50%;
  width: 100%;
  height: 100%;
  transform: translate(50%);
  z-index: -1;
}
.mobile-menu-bg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.12));

}
.mobile-menu-items  {
  width: 100%;
}
.mobile-menu-items .items {
  text-align: center;
  width: 25%;
  padding: 15px 0px 0px 0px;
  font-size: 10px;
}
.items a {
  color: #647796;
}
.item-store {
  background-color: white;
  padding: 6px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-top: -21px;
  border: solid 1px rgb(207, 207, 207);
}
.item-store span {
  font-size: 30px;
}
.items .active {
  color: #0073ef !important;
}
</style>
