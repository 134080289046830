<template>
  <div class="faq-main">
    <div class="text-center">
      <h3 class="title-border-buttom my-4">
        <lottie-circle></lottie-circle>
        {{ title }}
      </h3>
      <div class="my-4">
        <div class="container">
          <faq-box :questions="questions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LottieCircle from "./../components/LottieCircle";
import FaqBox from "./../components/FaqBox.vue";

export default {
  name: "FaqPage",
  components: { LottieCircle, FaqBox },
  data() {
    return {
      questions: [
        {
          question: "پیگیری و هزینه حمل بار با خریدار است؟",
          answer:
            "پیگیری حمل بار و هزینه حمل به عهده خریدار می‌باشد.",
        },
        {
          question: "نحوه تحویل بار چگونه است؟",
          answer: "با تکمیل خرید، تناژ خریداری‌شده به‌صورت خودکار به باربری‌های منتخب شما اعلام و مراحل حمل و نقل به سرعت آغاز می‌شود."
        },
        {
          question: "انصراف از خرید امکان پذیر است؟",
          answer:
            "انصراف از خرید تنها در صورت موجود بودن کالا بر روی سایت و عدم تغییر قیمت آن امکان پذیر است.",
        },
        {
          question: "درخواست فاکتور رسمی به چه صورتی است؟",
          answer:
            '<span>برای دریافت فاکتور رسمی طبق قانون جدید استفاده از سامانه مودیان شما می بایست قبل از تکمیل خرید از قسمت پروفایل یا قسمت صفحه خرید اقدام به احراز هویت نموده و گزینه</span><span class="text-blue-light"> فاکتور رسمی نیاز دارم </span><span>را انتخاب کنید.</span>',
        },
        {
          question: "كاربرد تايخچه كرايه در سامانه سيمان انلاين چيست ؟",
          answer:
            "با کمک تاریخچه کرایه می توانید نرخ کرایه جاری را بررسی نموده و مطابق نیاز خود کرایه ای بالاتر یا پایین تر از دیگران پیشنهاد دهید.",
        },
        {
          question: "نحوه ساخت یا ورود به حساب",
          answer:
            "شما با وارد کردن شماره تلفن همراه تان در صورتی که قبلا ثبت نام کرده باشید بعد از دریافت کد یکبار مصرف میتوانید وارد حساب کاربری تان شوید و در غیر این صورت می توانید نام و نام خانوادگی و یک رمز عبور را برای خود وارد کنید و ثبت نام تان را در وب سایت سیمان آینده انجام دهید",
        },
        {
          question: "کد ورود به من پیامک نمی شود",
          answer:
            "احتمالا یا موبایل تان در دسترسی نیست یا خطوط پیامکی ما مشغول هستند، شما میتوانید با رمز عبور ثابت تان به جای کد یکبار مصرف وارد وب سایت شوید",
        },
        {
          question: "چرا باید رمز عبور ثابت داشته باشیم؟",
          answer:
            "در برخی مناطق دسترسی به اینترنت امکان پذیر است اما آنتن دهی وجود ندارد میتوانید از ورود با رمز عبور استفاده کنید.",
        },
        {
          question: "چگونه رمز ثابت را تغییر دهم؟",
          answer:
            "در پنل کاربری تان شما می توانید از بخش حساب کاربری اقدام به تغییر رمز عبور تان نمایید",
        },
      ],
    };
  },
  computed: {
    title() {
      return "سوالات متداول";
    },
  },
};
</script>

<style scoped></style>
