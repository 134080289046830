export default {
    userLoggedIn: {},
    userCart: [],
    countProductUser: 0,
    userNotifications: [],
    countCurrentOrder: null,
    currency: 'ریال',
    breadCrumbItems: null,
    competition: null,
    minufactorer: null,
    contactUsDetail: {
        phone: null,
        mobile1: null,
        mobile2: null,
        email: null,
        address: null,
    },
    modalAfterDoing: null,
    vertionApp: '1.0.0',
    cart_status: false,
    notif_status: false,
    currentArzehForBuy: null,
    bankAccountsAyandeh: null,
    bankAccountsUser: [],
    userAddresses: [],
    userProducts: [], // User Products
    loadingUserProducts: true,
    buyHistory: [], // invoices
    buyHistoryTotal: 0,
    loadingBuyHistory: true,
    saleProducts: [], // Sale Products
    saleProductsTotal: 0,
    loadingSaleProducts: true,
    tickets: [], // User Tickets
    ticketTotal: 0,
    loadingTicket: false,
}
