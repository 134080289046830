export default {

    setBreadCrumb: (state,items) => {
        state.breadCrumbItems = items;
    },

    // Form Shop
    setOrderCountBuy: (state,value) => {
        state.countCurrentOrder = value;
    },
    decreaseCount: (state) => {
        state.countCurrentOrder--;
    },
    increaseCount: (state) => {
        state.countCurrentOrder++;
    },
    setMinufactorer: (state,value) => {
        state.minufactorer = value;
    },
    // competition
    setCompetition: (state,value) => {
        state.competition = value;
    },

    // Set Contact Us Details
    setContactUsDet: (state,value) => {
        state.contactUsDetail = value;
    },

    // Open Modal After Doing Success Any Level
    openModalAfterDoing: (state,value) => {
        state.modalIdAfterDoing = value;
    },


    // Authentication
    storeUserInfo: (state, user = null ) => {
        state.userLoggedIn = user;
    },

    storeCartInfo: (state, cart = null ) => {
        state.userCart = cart;
    },

    storeCountProduct: (state, countProduct = 0 ) => {
        state.countProductUser = countProduct;
    },

    // Cart
    changeStatusCart: (state, status) => {
        state.cart_status = status;
    },

    // Notifications
    changeStatusNotifications: (state, status) => {
        state.notif_status = status;
    },

    setCurrentArzeh: (state, arzeh) => {
        state.currentArzehForBuy = arzeh;
    },

    setBankAccountAyandeh: (state, bankAccounts) => {
        state.bankAccountsAyandeh = bankAccounts;
    },

    setBankAccountUser: (state, bankAccounts) => {
        state.bankAccountsUser = bankAccounts;
    },

    setUserAddresses: (state, addresses) => {
        state.userAddresses = addresses;
    },

    setUserProducts: (state, products) => {
        state.userProducts = products;
    },

    setLoadingUserProducts: (state, newStatus) => {
        state.loadingUserProducts = newStatus;
    },

    setBuyHistories: (state, invoices) => {
        state.buyHistory = invoices;
    },
    setBuyHistoriesTotal: (state, total) => {
        state.buyHistoryTotal = total;
    },
    setLoadingBuyHistory: (state, newStatus) => {
        state.loadingBuyHistory = newStatus;
    },

    setSaleProducts: (state, sale) => {
        state.saleProducts = sale;
    },
    setSaleProductsTotal: (state, total) => {
        state.saleProductsTotal = total;
    },
    setLoadingSaleProducts: (state, newStatus) => {
        state.loadingSaleProducts = newStatus;
    },

    setUserTickets: (state, tickets) => {
        state.tickets = tickets;
    },
    setUserTicketTotal: (state, total) => {
        state.ticketTotal = total;
    },
    setLoadingTickets: (state, newStatus) => {
        state.loadingTicket = newStatus;
    },
}
