export default {

    countCurrentOrder: (state) => {
        return state.countCurrentOrder;
    },
    itemsBreadCrumb: (state) => {
        return state.breadCrumbItems;
    },
    userInfo: (state) => {
        return state.userLoggedIn;
    },
    cartUser: (state) => {
        return state.userCart;
    },
    userNotifications: (state) => {
        return state.userNotifications;
    },
    currentArzehForBuy: (state) => {
        return state.currentArzehForBuy;
    },
    countProductUser: (state) => {
        return state.countProductUser;
    },

}
