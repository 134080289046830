<template>
  <div class="main-footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-5 fs-13">
          <img
            src="./../../assets/images/logo/logo_black.svg"
            alt="سیمان آینده"
            height="60"
            class="mb-3"
          />
          <p class="fs-12" style="line-height: 24px">
            سیمان آینده شرکت تولیدی بدون کارخانه است. که به صورت سفارشی محصولات
            خود را در کارخانه های ثالث تولید میکند. شما می توانید با استفاده از
            این پلتفرم بصورت آنلاین محصولات سیمان آینده و سایر کارخانه ها را
            خریداری نمائید.
          </p>
          <a
            referrerpolicy="origin"
            target="_blank"
            href="https://trustseal.enamad.ir/?id=494582&amp;Code=EzpRd65B5mbE2fGnykAXRvXIOP20YTdn"
            class="d-block"
            style="width: 100px"
            ><img
              referrerpolicy="origin"
              src="https://trustseal.enamad.ir/logo.aspx?id=494582&amp;Code=EzpRd65B5mbE2fGnykAXRvXIOP20YTdn"
              alt="enamad logo"
              height="100"
              width="100"
              code="EzpRd65B5mbE2fGnykAXRvXIOP20YTdn"
              style="cursor: pointer"
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4">
          <h3 class="bold fs-16 text-blue-dark mb-3">تماس با ما</h3>
          <div class="d-inline-block">
            <p>
              <span class="material-icons align-top fs-22">location_on</span>
              <span
                >دفتر مرکزی: تهران بزرگراه خرازی، برج تریتا، طبقه شش، سیمان
                آینده</span
              >
            </p>
            <p>
              <span class="material-icons align-top fs-22">location_on</span>
              <span>
                دفتر چابهار: سیستان و بلوچستان، چابهار، منطقه آزاد، نبش میدان
                کارگر، مجتمع بیزینس سنتر چابهار، طبقه اول، واحد 102
              </span>
            </p>
            <p>
              <span class="material-icons align-top fs-22">call</span>
              <a
                href="tel:02191306630"
                style="color: rgb(46, 56, 77); margin-right: 4px"
                >02191306630</a
              >
            </p>
            <p>
              <span class="material-icons align-top fs-22">call</span>
              <a
                href="tel:05435313317"
                style="color: rgb(46, 56, 77); margin-right: 4px"
                >05435313317</a
              >
            </p>
            <p>
              <span class="material-icons align-top fs-22">phone_iphone</span>
              <a
                href="tel:09120709976"
                style="color: rgb(46, 56, 77); margin-right: 4px"
                >09120709976</a
              >
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
          <h3 class="bold fs-16 text-blue-dark mb-3">دسترسی سریع</h3>
          <ul class="px-0 list-style-none">
            <li class="mb-3">
              <a class="text-blue-light" href="https://panel.ayandehcement.com/"
                >ورود به پنل خریدار</a
              >
            </li>
            <li class="mb-3">
              <a class="text-blue-light" href="https://admin.ayandehcement.com/"
                >ورود به پنل باربری</a
              >
            </li>
            <li class="mb-3">
              <router-link class="text-blue-light" :to="{name: 'Faq'}"
                >سوالات متداول</router-link
              >
            </li>
            <li class="mb-3">
              <router-link class="text-blue-light" :to="{name: 'About'}"
                >درباره ما</router-link
              >
            </li>
            <li class="mb-3">
              <router-link class="text-blue-light" :to="{name: 'ContactUs'}"
                >تماس با ما</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="bg-blue-light mt-5 text-center text-white"
      style="padding: 13px 0"
    >
      تمامی حقوق برای برند آینده محفوظ است.
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    contacts() {
      return this.$store.state.contactUsDetail;
    },
  },
};
</script>
