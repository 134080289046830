<template>
  <div class="w-100">
    <slider-home></slider-home>

    <div class="container mt-5 pt-3">
      <div class="row">
        <div class="col-12 col-md-6">
          <h4 class="bold fs-16">معرفی سامانه سیمان آینده</h4>
          <p>
            سامانه "سیمان آنلاین" برای تسهیل خرید سیمان و بهبود تجربه مشتریان طراحی شده است. این سامانه به شما این امکان را می‌دهد تا به‌راحتی سیمان مورد نیاز خود را سفارش دهید، وضعیت سفارش‌ها را پیگیری کنید و به‌صورت آنلاین به صورتحساب‌ها دسترسی داشته باشید. با استفاده از زیرساخت‌های پیشرفته، سیمان آنلاین خرید سیمان را به فرآیندی ساده و مطمئن تبدیل می‌کند.
          </p>

          <h4
            class="bold fs-14 position-relative mr-5 mr-md-0 my-4 mb-md-0 mt-md-5"
          >
            <div class="square_number_title">
              1
              <lottie-circle></lottie-circle>
            </div>
            <span class="mr-5">
              خرید آنلاین سیمان
            </span>
          </h4>
          <p class="mr-5 mt-2">
            سامانه سیمان آنلاین به شما این امکان را می‌دهد تا سیمان مورد نیاز خود را با مناسب‌ترین قیمت از کارخانه‌های معتبر کشور تهیه کنید و تجربه‌ای آسان از خرید آنلاین داشته باشید.
          </p>

          <h4
            class="bold fs-14 position-relative mr-5 mr-md-0 my-4 mb-md-0 mt-md-5"
          >
            <div class="square_number_title">
              2
              <lottie-circle></lottie-circle>
            </div>
            <span class="mr-5">
              پیگیری سفارش‌ها 
            </span>
          </h4>
          <p class="mr-5 mt-2">
            شما می‌توانید سفارش‌های خود را از لحظه خرید تا تحویل پیگیری کنید و همیشه از وضعیت سفارش خود مطلع باشید.
          </p>

          <h4
            class="bold fs-14 position-relative mr-5 mr-md-0 my-4 mb-md-0 mt-md-5 "
          >
            <div class="square_number_title">
              3
              <lottie-circle></lottie-circle>
            </div>
            <span class="mr-5">
              دسترسی به گردش حساب 
          </span>
          </h4>
          <p class="mr-5 mt-2">
            در این سامانه، به‌صورت آنلاین می‌توانید وضعیت مالی و صورتحساب‌های خود را بررسی کنید.
          </p>
        </div>
        <div class="col-12 col-md-6 ps-3 ps-md-0">
          <img src="./../assets/images/banner-left.png" alt="" width="100%" />
        </div>
      </div>

      <br /><br /><br /><br />

      <div class="row">
        <div class="col-12 text-center">
          <h3 class="title-border-buttom">
            <lottie-circle></lottie-circle>
            چرا باید ما را انتخاب کنید
          </h3>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >receipt_long</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">تأمین مستقیم از معتبرترین تولیدکنندگان </h5>
          <p>
            ما با همکاری نزدیک با کارخانه‌های پیشرو و معتبر سیمان، محصولات باکیفیتی را مستقیماً از منابع اصلی تأمین می‌کنیم. این باعث می‌شود تا شما به بهترین مواد اولیه و محصولات استاندارد دسترسی داشته باشید، بدون واسطه و با اطمینان کامل.
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >history_edu</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">سامانه آنلاین نوآورانه و یکپارچه</h5>
          <p>
            سیمان آنلاین یک سامانه جامع و نوآورانه است که خرید سیمان را به‌صورت کاملاً آنلاین و یکپارچه فراهم می‌کند. شما می‌توانید تمامی مراحل خرید، پیگیری سفارش و دسترسی به صورتحساب‌ها را به‌سادگی از طریق یک پنل کاربری متمرکز انجام دهید.
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >query_builder</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">سرعت و کارآمدی در تحویل</h5>
          <p>
            ما از فرآیندهای خودکار برای صدور حواله و اعلام بار استفاده می‌کنیم که به شما این امکان را می‌دهد که سفارش ها خود را بدون تأخیر و با سرعت بالا دریافت کنید. به علاوه، سیستم پیگیری آنلاین به شما کمک می‌کند تا همیشه از وضعیت حمل و نقل مطلع باشید.

          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >receipt</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">شفافیت کامل در قیمت و هزینه‌ها</h5>
          <p>ما به شفافیت و اعتمادسازی اهمیت می‌دهیم. در سیمان آنلاین، قیمت‌ها به‌صورت کاملاً شفاف و رقابتی ارائه می‌شوند و شما می‌توانید تاریخچه کرایه‌ها و هزینه‌های حمل را بررسی و مدیریت کنید تا بهترین تصمیمات مالی را بگیرید.
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >new_releases</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">سفارشی‌سازی و انعطاف‌پذیری در سفارش ها</h5>
          <p>
            یکی از مزیت‌های اصلی ما، امکان سفارشی‌سازی محصولات با توجه به نیازهای خاص شماست. چه در مقیاس کوچک و چه بزرگ، ما آمادگی داریم تا محصولات را مطابق با شرایط شما و نیازهای پروژه‌تان تهیه و ارسال کنیم.
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pb-3 mb-5">
          <span class="material-icons-outlined material-icon-bordered"
            >move_to_inbox</span
          >
          <h5 class="fw-bold text-blue-light fs-14 m-0 py-2">
            پشتیبانی همه‌جانبه و ارتباط مداوم
          </h5>
          <p>
            ما در سیمان آنلاین از طریق یک تیم پشتیبانی حرفه‌ای و متخصص همواره در کنار شما هستیم. از لحظه خرید تا تحویل، شما می‌توانید بر روی پشتیبانی سریع و کارآمد ما حساب کنید. هدف ما این است که تجربه خرید شما را بی‌نقص و بدون مشکل کنیم.
          </p>
        </div>
      </div>
    </div>

    <br /><br /><br /><br />

    <div class="container">
      <div>
        <div class="text-center">
          <h3 class="title-border-buttom">
            <lottie-circle></lottie-circle>
            سوالات متداول
          </h3>
        </div>
        <faq-box :questions="questions"></faq-box>
        <div class="text-center mt-4">
          <router-link :to="{ name: 'Faq' }" class="bold"
            >مشاهده بیشتر</router-link
          >
        </div>
        <br /><br /><br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import SliderHome from "./../components/SliderHome";
import FaqBox from "./../components/FaqBox";
import LottieCircle from "./../components/LottieCircle";

export default {
  components: {
    LottieCircle,
    SliderHome,
    FaqBox,
  },
  data() {
    return {
      questions: [
        {
          question: "پیگیری و هزینه حمل بار با خریدار است؟",
          answer:
            "پیگیری حمل بار و هزینه حمل به عهده خریدار می‌باشد.",
        },
        {
          question: "نحوه تحویل بار چگونه است؟",
          answer: "با تکمیل خرید، تناژ خریداری‌شده به‌صورت خودکار به باربری‌های منتخب شما اعلام و مراحل حمل و نقل به سرعت آغاز می‌شود."
        },
        {
          question: "انصراف از خرید امکان پذیر است؟",
          answer:
            "انصراف از خرید تنها در صورت موجود بودن کالا بر روی سایت و عدم تغییر قیمت آن امکان پذیر است.",
        },
        {
          question: "درخواست فاکتور رسمی به چه صورتی است؟",
          answer:
            '<span>برای دریافت فاکتور رسمی طبق قانون جدید استفاده از سامانه مودیان شما می بایست قبل از تکمیل خرید از قسمت پروفایل یا قسمت صفحه خرید اقدام به احراز هویت نموده و گزینه</span><span class="text-blue-light"> فاکتور رسمی نیاز دارم </span><span>را انتخاب کنید.</span>',
        },
        {
          question: "كاربرد تايخچه كرايه در سامانه سيمان انلاين چيست ؟",
          answer:
            "با کمک تاریخچه کرایه می توانید نرخ کرایه جاری را بررسی نموده و مطابق نیاز خود کرایه ای بالاتر یا پایین تر از دیگران پیشنهاد دهید.",
        },
        {
          question: "نحوه ساخت یا ورود به حساب",
          answer:
            "شما با وارد کردن شماره تلفن همراه تان در صورتی که قبلا ثبت نام کرده باشید بعد از دریافت کد یکبار مصرف میتوانید وارد حساب کاربری تان شوید و در غیر این صورت می توانید نام و نام خانوادگی و یک رمز عبور را برای خود وارد کنید و ثبت نام تان را در وب سایت سیمان آینده انجام دهید",
        },
        {
          question: "کد ورود به من پیامک نمی شود",
          answer:
            "احتمالا یا موبایل تان در دسترسی نیست یا خطوط پیامکی ما مشغول هستند، شما میتوانید با رمز عبور ثابت تان به جای کد یکبار مصرف وارد وب سایت شوید",
        },
        {
          question: "چرا باید رمز عبور ثابت داشته باشیم؟",
          answer:
            "در برخی مناطق دسترسی به اینترنت امکان پذیر است اما آنتن دهی وجود ندارد میتوانید از ورود با رمز عبور استفاده کنید.",
        },
        {
          question: "چگونه رمز ثابت را تغییر دهم؟",
          answer:
            "در پنل کاربری تان شما می توانید از بخش حساب کاربری اقدام به تغییر رمز عبور تان نمایید",
        },
      ],
    };
  },
};
</script>
