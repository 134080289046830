import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/src/jquery.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "@/assets/css/app.css";
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import { BootstrapVue } from "bootstrap-vue";

Vue.config.productionTip = false;

Vue.use(LottieVuePlayer);
Vue.use(BootstrapVue);
// Vee Validate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
